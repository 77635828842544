import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Avis Car Rental
			</title>
			<meta name={"description"} content={"Conduisez l'extraordinaire - Améliorez votre voyage avec Avis\n"} />
			<meta property={"og:title"} content={"Avis Car Rental"} />
			<meta property={"og:description"} content={"Conduisez l'extraordinaire - Améliorez votre voyage avec Avis\n"} />
			<meta property={"og:image"} content={"https://bordeauxblis.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://bordeauxblis.com/media/3453452236623.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://bordeauxblis.com/media/3453452236623.png"} />
			<meta name={"msapplication-TileImage"} content={"https://bordeauxblis.com/media/3453452236623.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 200px 0" quarkly-title="Contacts-4">
			<Override slot="SectionContent" flex-direction="column" grid-template-columns="repeat(2, 1fr)" grid-gap="56px 54px" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="0px 0px 25px 0px"
				md-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 0 0px 0px"
					width="40%"
					margin="0px 5% 0px 0px"
					md-width="auto"
				>
					<Text
						lg-padding="0px 0px 0px 0px"
						sm-font="normal 700 36px/1.2 --fontFamily-sans"
						margin="0px 0px 18px 0px"
						font="normal 600 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						text-align="left"
						padding="0px 0 0px 0px"
						lg-width="100%"
						lg-margin="0px 0px 25px 0px"
					>
						Emplacement et contact
					</Text>
					<Text
						font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
						lg-margin="0px 0 50px 0px"
						text-align="left"
						sm-margin="0px 0 35px 0px"
						margin="16px 0 50px 0px"
						color="#60666d"
					>
						Pour réserver votre prochaine aventure en voiture, contactez-nous au ou envoyez-nous un e-mail.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="grid"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="36px 34px"
					lg-grid-template-columns="1fr"
					sm-grid-template-columns="1fr"
					width="55%"
					md-width="auto"
				>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 25px 30px 25px"
						border-color="--color-lightD2"
						background="#eff2f2"
						border-radius="15px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
							E-mail
						</Text>
						<Link
							href="mailto:contact@bordeauxblis.com"
							color="#60666d"
							font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
							margin="0px 0px 5px 0px"
							display="block"
							text-decoration-line="initial"
						>
							contact@bordeauxblis.com
						</Link>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 25px 30px 25px"
						border-color="--color-lightD2"
						background="#eff2f2"
						border-radius="15px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
							Téléphone
						</Text>
						<Link
							href="tel:+33 1 39 16 96 72"
							color="#60666d"
							font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
							text-decoration-line="initial"
							display="block"
						>
							+33 1 39 16 96 72
						</Link>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 25px 30px 25px"
						border-color="--color-lightD2"
						background="#eff2f2"
						border-radius="15px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
							Adresse
						</Text>
						<Text color="#60666d" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" text-decoration-line="initial" display="block">
							23 Roscoe Ln, Liverpool L1 9JD, UK
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 25px 30px 25px"
						border-color="--color-lightD2"
						background="#eff2f2"
						border-radius="15px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 22px/1.2 --fontFamily-sans">
							Heures de travail
						</Text>
						<Text
							href="tel:+9877654321223"
							color="#60666d"
							font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
							text-decoration-line="initial"
							display="block"
						>
							LUN-VEN 9 - 18
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});